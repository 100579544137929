import { useEffect, useState } from "react";
import { tick } from "../../../helpers/tick";
import Timer from "../Timer/Timer";
import styles from "./AboutProgress.module.scss";

import patern from "../../../assets/images/weddingFour/patern.webp";

export default function AboutProgress() {
  const targetDate = "2024-10-24T16:15:30";
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["Արթուր & Լուսինե"];
  const period = 2500;
  useEffect(() => {
    let ticker = setInterval(() => {
      tick(
        setDelta,
        text,
        setLoopNum,
        setIsDeleting,
        setIndex,
        toRotate,
        period,
        setText,
        isDeleting,
        loopNum
      );
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);
  return (
    <div className={styles.aboutProgress}>
      <div className={styles.progressItems}>
        <div className={styles.about}>
          <p>Սիրով կսպասենք Ձեզ:</p>
          <img src={patern} alt="logo" />
        </div>
        <div className={styles.timerDate}>
          <p>Հարսանիքին մնացել է</p>
          <Timer targetDate={targetDate} />
        </div>
      </div>
    </div>
  );
}
