import React from 'react'
import styles from "./NotFound.module.scss" 

const NotFound = () => {
  return (
    <div className={styles.not_found}>
      <h1>404</h1>
      <p>We don't have this page</p>
    </div>
  )
}

export default NotFound
