import { useEffect, useState } from "react";
import { tick } from "../../../helpers/tick";
import Timer from "../Timer/Timer";
import MovingText from "react-moving-text";

import logo from "../../../assets/images/weddingThree/logo.png"
import img from "../../../assets/images/weddingThree/img3.webp";
// import img from "../../../assets/images/weddingThree/wedd3.jpg"

import styles from "./Header.module.scss";


export default function Header({ isLoading }) {
  const targetDate = "2024-09-24T16:15:30";
  const backgroundImageUrl = img;
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["Արթուր & Լուսինե"];
  const period = 2500;
  useEffect(() => {
    let ticker = setInterval(() => {
      tick(
        setDelta,
        text,
        setLoopNum,
        setIsDeleting,
        setIndex,
        toRotate,
        period,
        setText,
        isDeleting,
        loopNum
      );
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  return (
    <header
      className={styles.headerCont}
      style={{ backgroundImage: `url(${backgroundImageUrl})` }}
    >
      <div className={styles.headerInfo}>
        <div
          className={styles.brideNames}
          style={{
            opacity: isLoading ? 0 : 1,
            transform: `translateX(${isLoading ? 150 : 0}px)`,
            transition: "opacity 0.5s, transform 0.8s",
          }}
        >
          <img
            src={logo}
            alt=""
            width="128"
          />
          <h3 className={styles.date}>24.09.2024</h3>
          <MovingText
            type="fadeInFromBottom"
            duration="1000ms"
            delay="0s"
            direction="normal"
            timing="ease"
            iteration="1"
            fillMode="none"
            className={styles.namesB}
          >
            <div className={styles.namesB}>
              <h2>ԱԼԵՔՍԱՆԴՐ ԵՎ ԿԱՐԻՆԱ</h2>
              {/* <h2>ԵՎ</h2> */}
              {/* <h2>ԿԱՐԻՆԱ</h2> */}
            </div>
          </MovingText>
        </div>
        <div className={styles.timer}>
          <Timer targetDate={targetDate} />
        </div>
      </div>
    </header>
  );
}
