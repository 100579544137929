import { useEffect, useRef, useState } from "react";
import { tick } from "../../../helpers/tick";
import patern from "../../../assets/images/weddingFour/patern.webp";
import img from "../../../assets/images/weddingFour/img4.webp";

import styles from "./Header.module.scss";
import BackgroundMusic from "../BackgroundMusic/BackgroundMusic";

export default function Header({ onImageLoad, isLoading }) {
  const backgroundImageUrl = img;
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["Արթուր & Լուսինե"];
  const period = 2500;
  useEffect(() => {
    let ticker = setInterval(() => {
      tick(
        setDelta,
        text,
        setLoopNum,
        setIsDeleting,
        setIndex,
        toRotate,
        period,
        setText,
        isDeleting,
        loopNum
      );
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header>
      <div className={styles.music}>
        <BackgroundMusic />
      </div>
      <div
        className={styles.headerCont}
        style={{
          backgroundImage: `url(${backgroundImageUrl})`,
        }}
      >
        <div className={styles.headerInfo}>
          <div className={styles.brideNames}>
            <div className={styles.namesB}>
              <h2>Արթուր ԵՎ Լուսինե</h2>
            </div>
            <div className={styles.bottomBox}>
              <h4 className={styles.weddText}>
                Սիրով հրավիրում ենք Ձեզ մեր կյանքի ամենակարևոր տոնակատարությանը՝
                մեր հարսանիքին
                <img src={patern} alt="" />
              </h4>
              <h3 className={styles.date}>24.10.2024</h3>
            </div>
          </div>
        </div>
      </div>
      <img
        src={backgroundImageUrl}
        alt="background"
        style={{ display: "none" }}
        onLoad={onImageLoad}
        onError={() => console.error("Failed to load the image.")}
      />
    </header>
  );
}
