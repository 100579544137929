import { Link } from "react-scroll";
import styles from "./Navbar.module.scss";
import BackgroundMusic from "../BackgroundMusic/BackgroundMusic";

export default function Navbar() {
  return (
    <nav>
      <div className={styles.backgroundMusic}>
        <BackgroundMusic />
      </div>
      <div className={styles.container}>
        <div className={styles.navLists}>
          <ul>
            <li>
              <Link
                to="section"
                activeClass="active"
                spy={true}
                smooth={true}
                offset={-450}
                duration={1000}
              >
                Ծրագիր
              </Link>
            </li>
            <li>
              <Link
                to="contactUs"
                activeClass="active"
                spy={true}
                smooth={true}
                offset={-70}
                duration={2000}
              >
                Կապ զույգի հետ
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
