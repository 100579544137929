import { useEffect, useState } from "react";
import { ContactUs } from "../ContactUs/ContactUs";
import Models from "../models/Models";

import rest from "../../../assets/images/weddingThree/image00017.webp";
import styles from "./Main.module.scss";

export default function Main() {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <main>
      <div className={styles.container}>
        <div className={styles.mainContent}>
          <div
            className="animated-content"
            style={{
              opacity: scrollPosition > 100 ? 1 : 0,
              transform: `translateY(${scrollPosition > 200 ? 0 : 50}px)`,
              transition: "opacity 0.5s, transform 0.8s",
            }}
          >
            <div className={styles.animatedContent}>
              <div className={styles.imgSlider}>
                <div
                  className={styles.radiusOne}
                  style={{
                    opacity: scrollPosition > 100 ? 1 : 0,
                    transform: `scale(${scrollPosition > 100 ? 1 : 0.8})`,
                    filter: `brightness(${scrollPosition > 250 ? 1 : 0.3})`,
                    transition: "opacity 0.5s, filter 0.8s",
                  }}
                ></div>
                <div
                  className={styles.radiusTwo}
                  style={{
                    opacity: scrollPosition > 200 ? 1 : 0,
                    filter: `brightness(${scrollPosition > 400 ? 1 : 0.3})`,
                    transition: "opacity 0.5s, filter 0.8s",
                  }}
                ></div>
              </div>
            </div>
            <div className={styles.forGuests}>
              <p>
                Թանկագին հարազատներ <br /> հրավիրում ենք Ձեզ մասնակցելու մեր
                հարսանյաց հանդեսին, որը տեղի <br /> կունենա 2024 թվականի
                սեպտեմբերի 24-ին։{" "}
              </p>
              {/* <h3 className={styles.date}>05/10/2024</h3> */}
            </div>
            {/* <div className={styles.weddingProgress}>
              <h3 className={styles.progress}>Ծրագիր</h3>
            </div> */}
          </div>
          {/* <div
            className={styles.animatedContent}
            style={{
              opacity: scrollPosition > 100 ? 1 : 0,
              transform: `translateY(${scrollPosition > 200 ? 0 : 50}px)`,
              transition: "opacity 0.5s, transform 0.8s",
            }}
          >
            <div className={styles.imgSlider}>
              <div
                className={styles.radiusThree}
                style={{
                  opacity: scrollPosition > 100 ? 1 : 0,
                  filter: `brightness(${scrollPosition > 900 ? 1 : 0.3})`,
                  transition: "opacity 0.5s, filter 0.8s",
                }}
              ></div>
            </div>
          </div> */}

          {/* <div
            className="animated-content"
            style={{
              opacity: scrollPosition > 600 ? 1 : 0,
              transform: `translateY(${scrollPosition > 600 ? 0 : 50}px)`,
              transition: "opacity 0.5s, transform 0.8s",
            }}
          >
            <AboutProgress />
          </div> */}
        </div>
      </div>
      <div
        className="animated-content"
        style={{
          opacity: scrollPosition > 900 ? 1 : 0,
          transform: `translateY(${scrollPosition > 900 ? 0 : 50}px)`,
          transition: "opacity 0.5s, transform 0.8s",
        }}
      >
        <Models
          eventType={"Պսակադրություն"}
          eventTime={"15:30"}
          placeName={"ԱԲՈՎՅԱՆԻ ՍՈՒՐԲ ՀՈՎՀԱՆՆԵՍ ՄԿՐՏԻՉ ԵԿԵՂԵՑԻ"}
          eventAddres={"Կոտայքի մարզ, ք․ Աբովյան, 3-րդ միկրոշրջան"}
          buttonText={"Քարտեզ"}
          scroll={1000}
          maxScroll={2000}
          href={"https://yandex.com/maps/-/CDDyY4lL"}
          backgroundImageUrl={
            "https://abovyan.com/wp-content/gallery/st-hovhaness-church-openning/3.jpg"
          }
        />
        <Models
          eventType={"Խնջույք"}
          eventTime={"17:30"}
          placeName={"ՎԻԼԼԱ"}
          eventAddres={"Կոտայքի մարզ, Ջրվեժ, 16-րդ փողոց, 10/2"}
          buttonText={"Քարտեզ"}
          scroll={1800}
          maxScroll={2800}
          href={"https://yandex.com/maps/-/CDDyY4lL"}
          backgroundImageUrl={rest}
        />
      </div>
      <div
        className="animated-content"
        // style={{
        //   opacity: scrollPosition > 1900 ? 1 : 0,
        //   transform: `translateY(${scrollPosition > 1900 ? 0 : 50}px)`,
        //   transition: "opacity 0.5s, transform 0.8s",
        // }}
        style={{
          opacity: scrollPosition > 2300 ? 1 : 0,
          filter: `brightness(${scrollPosition > 2400 ? 1 : 0.3})`,
          transition: "opacity 0.5s, filter 0.8s",
        }}
      >
        <ContactUs />
      </div>
    </main>
  );
}
