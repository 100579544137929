import { useEffect, useState } from "react";
import Models from "../models/Models";

import church from "../../../assets/images/weddingTwo/h4.jpeg";
import calendar from "../../../assets/images/weddingFour/calendar.jpeg";
import styles from "./Main.module.scss";

export default function Main() {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };
  console.log(scrollPosition);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <main>
      <div className={styles.imgSlides}>
        <div
          className={styles.imgOne}
          style={{
            opacity: scrollPosition > 100 ? 1 : 0,
            transform: `translateX(${scrollPosition > 100 ? 0 : -150}px)`,
            transition: "opacity 0.5s, transform 0.8s",
          }}
        ></div>
        <div
          className={styles.imgTwo}
          style={{
            opacity: scrollPosition > 100 ? 1 : 0,
            transform: `translateX(${scrollPosition > 100 ? 0 : 150}px)`,
            transition: "opacity 0.5s, transform 0.8s",
          }}
        ></div>
      </div>
      <div className={styles.container}>
        <div className={styles.mainContent}>
          <img src={calendar} alt="calendar" className={styles.calendar} />
          <div
            style={{
              opacity: scrollPosition > 100 ? 1 : 0,
              transform: `translateY(${scrollPosition > 200 ? 0 : 50}px)`,
              transition: "opacity 0.5s, transform 0.8s",
            }}
          >
            <div className={styles.weddingProgress}>
              <h3 className={styles.progress}>Օրվա ծրագիր</h3>
            </div>
          </div>
          <div style={{ marginTop: "50px" }}>
            <Models
              time={"14:30"}
              placeImg={church}
              placeName={"Սուրբ Սարգիս Եկեղեցի"}
              placeStreet={"ք․ Երևան, Իսրայելյան փողոց 19"}
              link={"https://yandex.ru/maps/-/CDwGm6ld"}
            />
          </div>
          <div style={{ marginTop: "50px" }}>
            <Models
              time={"17:30"}
              placeImg={
                "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/29/b5/00/b6/2.jpg?w=1400&h=-1&s=1"
              }
              placeName={"«Ֆլորենս» Ռեստորանային Համալիր"}
              placeStreet={"Բարբյուսի փող. 64/2"}
              link={"https://yandex.com.ge/maps/-/CDD15Fzi"}
            />
          </div>
          <p className={styles.theEnd}>
            <div className={styles.time}>23:30</div>
            <div className={styles.type}>Ավարտ </div>
          </p>
        </div>
      </div>
    </main>
  );
}
