import styles from "./AboutProgress.module.scss";

import start from "../../../assets/images/weddingTwo/start.svg";
import church from "../../../assets/images/weddingTwo/pskadrutyun.svg";
import bottles from "../../../assets/images/weddingTwo/handes.svg";
import matani from "../../../assets/images/weddingTwo/matani.svg";

export default function AboutProgress() {
  return (
    <div className={styles.aboutProgress}>
      <div className={styles.progressItems}>
        <p className={styles.boldWord} id="section">
          12:00
        </p>
        <div className={styles.imgBlock}>
          <img src={start} alt="" width="84px" />
          <div className={styles.aroundBorder}></div>
        </div>

        <div className={styles.infoBox}>
          <p className={styles.boldWord}>Միջոցառման սկիզբ</p>
          <p className={styles.lastBoldWord}>
            Հարսի տուն
          </p>
          <p>
            <a
              href="https://yandex.ru/maps/-/CDwGmRJg"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                fill="#c6d1c1"
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 395.71 395.71"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <g>
                    <path d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738 c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388 C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191 c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z"></path>{" "}
                  </g>{" "}
                </g>
              </svg>
              Աջափնյակ Գ-1 35/16
            </a>
          </p>
        </div>
      </div>
      <div className={styles.progressItems}>
        <p className={styles.boldWord}>14:20</p>
        <div className={styles.imgBlock}>
          <img src={church} alt="" width="84px" />
          <div className={styles.aroundBorder}></div>
        </div>

        <div className={styles.infoBox}>
          <p className={styles.boldWord}>Պսակադրություն</p>
          <p className={styles.lastBoldWord}>Սուրբ Սարգիս</p>
          <p>
            <a
              href="https://yandex.ru/maps/-/CDwGm6ld"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                fill="#c6d1c1"
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 395.71 395.71"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <g>
                    <path d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738 c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388 C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191 c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z"></path>{" "}
                  </g>{" "}
                </g>
              </svg>
              Երևան, Ռաֆաել Իսրայելյան փողոց
            </a>
          </p>
        </div>
      </div>
      <div className={styles.progressItems} id="weddingAddress">
        <p className={styles.boldWord}>17:30</p>
        <div className={styles.imgBlock}>
          <img src={bottles} alt="" width="84px" />
          <div className={styles.aroundBorder}></div>
        </div>

        <div className={styles.infoBox}>
          <p className={styles.boldWord}>Հարսանյաց հանդիսություն</p>
          <p className={styles.lastBoldWord}>Աղաբաբյանս Հոլլ</p>
          <p>
            <a
              href="https://yandex.ru/maps/-/CDwGmG2j"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                fill="#c6d1c1"
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 395.71 395.71"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <g>
                    <path d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738 c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388 C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191 c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z"></path>{" "}
                  </g>{" "}
                </g>
              </svg>
              Երևան, Աջափնյակ Նազարբեկյան 25/5
            </a>
          </p>
        </div>
      </div>
      <div className={styles.progressItems} id="weddingAddress">
        <p className={styles.boldWord}>23:30</p>
        <div className={styles.imgBlock}>
          <img src={matani} alt="" width="84px" />
        </div>

        <div className={styles.infoBox}>
          <p className={styles.boldWord}>Միջոցառման ավարտ</p>
        </div>
      </div>
    </div>
  );
}
