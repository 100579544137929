import music from "../../../assets/music3.mp3";
import play from "../../../assets/images/play2.png";
import pause from "../../../assets/images/pause3.png";

import styles from "./BackgroundMusic.module.scss";

export default function BackgroundMusic({ isPlaying, setIsPlaying, audioRef }) {
  const toggleAudio = () => {
    setIsPlaying(!isPlaying);
  };
  return (
    <div>
      <img
        src={isPlaying ? pause : play}
        alt="playIcon"
        onClick={toggleAudio}
        className={styles.playIcon}
      />
      <audio ref={audioRef} loop>
        <source src={music} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
}
