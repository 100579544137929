import { useEffect, useState } from "react";
import AboutProgress from "../aboutProgress/AboutProgress";
import { ContactUs } from "../ContactUs/ContactUs";

import styles from "./Main.module.scss";
import Models from "../models/Models";

export default function Main() {
  const [scrollPosition, setScrollPosition] = useState(0);

  // Update scroll position on scroll event
  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <main>
      <div className={styles.container}>
        <div className={styles.mainContent}>
          <div
            className="animated-content"
            style={{
              opacity: scrollPosition > 100 ? 1 : 0,
              transform: `translateY(${scrollPosition > 200 ? 0 : 50}px)`,
              transition: "opacity 0.5s, transform 0.8s",
            }}
          >
            <div className={styles.forGuests}>
              <p>
                Ուրախ կլինենք կիսել Ձեզ հետ մեր կյանքի գեղեցիկ եւ սպասված օրը
              </p>
              <h3 className={styles.date}>09/09/2024</h3>
            </div>
            <div className={styles.weddingProgress}>
              <h3 className={styles.progress}>Ծրագիր</h3>
            </div>
          </div>

          <div
            className="animated-content"
            style={{
              opacity: scrollPosition > 600 ? 1 : 0,
              transform: `translateY(${scrollPosition > 600 ? 0 : 50}px)`,
              transition: "opacity 0.5s, transform 0.8s",
            }}
          >
            <AboutProgress />
          </div>
          <div
            className="animated-content"
            style={{
              opacity: scrollPosition > 1400 ? 1 : 0,
              transform: `translateY(${scrollPosition > 1400 ? 0 : 50}px)`,
              transition: "opacity 0.5s, transform 0.8s",
            }}
          >
            <Models />
          </div>
          <div
            className="animated-content"
            style={{
              opacity: scrollPosition > 1900 ? 1 : 0,
              transform: `translateY(${scrollPosition > 1900 ? 0 : 50}px)`,
              transition: "opacity 0.5s, transform 0.8s",
            }}
          >
            <div style={{ marginTop: "80px" }}>
              <ContactUs />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
