import Timer from "../Timer/Timer";
import { tick } from "../../../helpers/tick";

import styles from "./Header.module.scss";

import img from "../../../assets/images/photo1.jpg";
import { useEffect, useState } from "react";

export default function Header({ onImageLoad, isLoading }) {
  // const targetDate = "2024-09-15T16:10:00";
  const backgroundImageUrl = img;
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["Արթուր & Լուսինե"];
  const period = 2500;
  useEffect(() => {
    let ticker = setInterval(() => {
      tick(
        setDelta,
        text,
        setLoopNum,
        setIsDeleting,
        setIndex,
        toRotate,
        period,
        setText,
        isDeleting,
        loopNum
      );
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  return (
    <header>
      <div
        className={styles.headerCont}
        style={{ backgroundImage: `url(${backgroundImageUrl})` }}
      >
        <div className={styles.container}>
          <div className={styles.headerInfo}>
            <div
              className={styles.brideNames}
              style={{
                opacity: isLoading ? 0 : 1,
                transform: `translateX(${isLoading ? 150 : 0}px)`,
                transition: "opacity 0.5s, transform 0.8s",
              }}
            >
              <div className={styles.namesB}>
                <h2>Գեորգի</h2>
                <h2>&</h2>
                <h2>Յուլիա</h2>
              </div>
              <h3 className={styles.date}>09/09/2024</h3>
              {/* <h2
                className="txt-rotate"
                dataperiod="1000"
                data-rotate='[ "Արթուր & Լուսինե" ]'
              >
                <span className="wrap">{text}</span>
              </h2> */}
            </div>
            {/* <div className={styles.timer}>
              <Timer targetDate={targetDate} />
            </div> */}
          </div>
        </div>
        <img
          src={backgroundImageUrl}
          alt="background"
          style={{ display: "none" }}
          onLoad={onImageLoad}
          onError={() => console.error("Failed to load the image.")}
        />
      </div>
    </header>
  );
}
