import styles from "./Models.module.scss";

export default function Models({ time, placeName, placeStreet, placeImg, link }) {
  return (
    <div className={styles.models}>
      <div className={styles.placeBox}>
        <div className={styles.time}>{time}</div>
        <div className={styles.placeName}>{placeName} </div>
        <div className={styles.placeStreet}>{placeStreet} </div>
        <img src={placeImg} alt="" />
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
        >
          Դիտել Քարտեզում
        </a>
      </div>
    </div>
  );
}
