import React, { useEffect, useRef, useState } from "react";
import Navbar from "./Navbar/Navbar";
import Header from "./Header/Header";
import Main from "./Main/Main";
import Footer from "./Footer/Footer";
import Loader from "./Loader/Loader";
import { NotificationContainer } from "react-notifications";
import { geoXY } from "../../constant/seo";
import { Helmet } from "react-helmet-async";
import SeoCard from "../seoCard";

import styles from "./Wedding.module.scss";

const WeddingThree = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const startMusic = () => {
    setIsPlaying(true);
  };

  useEffect(() => {
    if (isPlaying && audioRef.current) {
      audioRef.current.play().catch((error) => {
        console.log("Audio playback was prevented:", error);
      });
    } else if (!isPlaying && audioRef.current) {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  return (
    <div className={styles.App}>
      {isLoading ? (
        <Loader
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          startMusic={startMusic}
        />
      ) : (
        <div>
          <div className={styles.position}>
            <Navbar
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              audioRef={audioRef}
            />
          </div>
          <Header isLoading={isLoading} />
          <Main />
          <Footer />
          <NotificationContainer />
          <SeoCard seo={geoXY} />
        </div>
      )}
      <Helmet>
        <title>Ալեքսանդր & Կարինա</title>
        <meta name="description" content="Ալեքսանդր և Կարինա" />
      </Helmet>
    </div>
  );
};

export default WeddingThree;
