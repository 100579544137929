import React from "react";
import websiteLogo from "../../assets/images/hravLogo.png";
import MovingText from "react-moving-text";

import styles from "./Loader.module.scss";

const MainLoader = () => {
  return (
    <div className={styles.loader_container}>
      <img src={websiteLogo} alt="" className={styles.mainLoader} />
      {/* <div className={styles.loader_circle}></div> */}
      <MovingText
        type="slideInFromLeft"
        duration="2000ms"
        direction="normal"
        timing="ease"
        iteration="1"
        fillMode="none"
      >
        <h1 className={styles.loader_text}>Website is Coming</h1>
      </MovingText>
    </div>
  );
};

export default MainLoader;
