import { useEffect, useState } from "react";
import MovingText from "react-moving-text";

import styles from "./Models.module.scss";

export default function Models({
  eventType,
  eventTime,
  eventAddres,
  placeName,
  buttonText,
  backgroundImageUrl,
  scroll,
  maxScroll,
  href
}) {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div
      className={styles.models}
      style={{
        backgroundImage: `url(${backgroundImageUrl})`,
        backgroundPosition: "top",
        filter: `brightness(${scrollPosition > maxScroll ? 0.3 : scrollPosition > scroll ? 1 : 0.3})`,
        transition: "opacity 0.5s, transform 0.8s, filter 0.8s",
      }}
    >
      <div className={styles.black}></div>
      <div className={styles.mainContent}>
        <p className={styles.eventType}>{eventType}</p>
        {scrollPosition > scroll ? (
          <MovingText
            type="fadeInFromBottom"
            duration="1000ms"
            delay="0s"
            direction="normal"
            timing="ease"
            iteration="1"
            fillMode="none"
          >
            <p className={styles.eventTime}>{eventTime}</p>
          </MovingText>
        ) : (
          <MovingText
            type="fadeInFromTop"
            duration="1000ms"
            delay="0s"
            direction="normal"
            timing="ease"
            iteration="1"
            fillMode="none"
          >
            <p className={styles.eventTime}>{eventTime}</p>
          </MovingText>
        )}

        <p className={styles.placeName}>
          {placeName}
          <span className={styles.eventAddress}>{eventAddres}</span>
        </p>
        <a
          href={href}
          target="_blank"
          rel="noreferrer"
        >
          {buttonText}
        </a>
      </div>
    </div>
  );
}
