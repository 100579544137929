import { Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Wedding from "./components/weddingOne/Wedding";
import MainLoader from "./components/Loader/MainLoader";
import NotFound from "./components/NotFound/NotFound";
import WeddingTwo from "./components/weddingTwo/WeddingTwo";
import WeddingThree from "./components/weddingThree/WeddingThree";
import WeddingFour from "./components/weddingFour/WeddingFour";

import "./App.css";

function App() {
  return (
    <>
      <Helmet>
        <title>Hraviratomser.am Հարսանյաց հրավիրատոմսեր</title>
        <meta
          name="description"
          content="Hraviratomser.am Հարսանյաց հրավիրատոմսեր"
        />
      </Helmet>
      <Routes>
        <Route index element={<MainLoader />} />
        <Route path={"/georgi-yulia"} element={<Wedding />} />
        <Route path={"/beno-ani"} element={<WeddingTwo />} />
        <Route path={"/alexandr-karina"} element={<WeddingThree />} />
        <Route path={"/artur-lusine"} element={<WeddingFour />} />
        <Route path={"*"} element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
