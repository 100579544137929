import { Link } from "react-router-dom";
import hrav from "../../assets/images/hrav.jpeg";
import styles from "./Footer.module.scss";

export default function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.leftSide}>
        <Link to={"/"}>
          <img src={hrav} alt="" />
        </Link>
      </div>
      <div className={styles.rightSide}>
        <a
          target="_blank"
          href="https://www.instagram.com/hraviratomser.am?igsh=cWczYTFqMmU4aG1p"
          rel="noreferrer"
        >
          Հրավիրատոմսեր.<span>am</span>
        </a>
        <a href="tel:+37455728273">Կապ մեզ հետ</a>
      </div>
    </div>
  );
}
