import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";

import logo from "../../../assets/images/weddingThree/logo.png"
import styles from "./ContactUs.module.scss";

export const ContactUs = () => {
  const form = useRef();
  const [errorMessage, setErrorMessage] = useState("");
  const [lastSent, setLastSent] = useState(0);

  let errorMessageTimeout = useRef(null);
  const [selectedRadio, setSelectedRadio] = useState({
    name: "",
    value: "",
  });

  const handleRadioChange = (e) => {
    setSelectedRadio({
      name: e.target.name,
      value: e.target.value,
    });
  };

  // const sendEmail = (e) => {
  //   e.preventDefault();
  //   const userName = form.current.user_name.value;

  //   const now = Date.now();
  //   if (now - lastSent < 10000) {
  //     setErrorMessage("Please wait 10 seconds between submissions.");
  //     errorMessageTimeout.current = setTimeout(() => {
  //       setErrorMessage("");
  //     }, 10000);
  //     return;
  //   }

  //   let hasError = false;

  //   if (!selectedRadio.value || !userName.trim()) {
  //     setErrorMessage("Խնդրում ենք լրացնել բոլոր դաշտերը");
  //     hasError = true;
  //   }

  //   if (hasError) return;

  //   emailjs
  //     .sendForm(
  //       // "service_4zfi84i",
  //       "template_p6hirqr",
  //       form.current,
  //       "uLq7FAJyPzhHOGzW7"
  //     )
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //         setErrorMessage(""); // clear error message on successful send
  //         setLastSent(now); // Update the last sent timestamp
  //         NotificationManager.success(
  //           "Ձեր նամակը հաջողությամբ ուղարկվել է",
  //           "Հաջողվեց"
  //         );
  //         const timer = setTimeout(() => {
  //           setLastSent(0); // Reset the lastSent timestamp after 10 seconds
  //         }, 10000);

  //         // Clear the timeout when the component is unmounted
  //         return () => clearTimeout(timer);
  //       },
  //       (error) => {
  //         console.log(error.text);
  //         setErrorMessage("Error sending email. Please try again.");
  //         NotificationManager.error(
  //           "Error",
  //           "Error sending email. Please try again."
  //         );

  //         // update with a more specific error message if needed
  //       }
  //     );
  // };

  function Submit(e) {
    const formEle = document.querySelector("form");
    e.preventDefault();
    const now = Date.now();
    if (now - lastSent < 10000) {
      setErrorMessage("Please wait 10 seconds between submissions.");
      errorMessageTimeout.current = setTimeout(() => {
        setErrorMessage("");
      }, 10000);
      return;
    }
    const userName = form.current.Name.value;

    let hasError = false;

    if (!selectedRadio.value || !userName.trim()) {
      setErrorMessage("Խնդրում ենք լրացնել բոլոր դաշտերը");
      hasError = true;
    }

    if (hasError) return;
    const formDatab = new FormData(formEle);
    axios
      .post(
        "https://script.google.com/macros/s/AKfycbyFbqjP6u2KkvUdllJMNc29mI_qJVLD-Qty83OmGKIvhSvmIpfb73qCxy1VhGs2Xg/exec",
        formDatab
      )
      .then((res) => res)
      .then(
        (result) => {
          setErrorMessage("");
          setLastSent(now);
          NotificationManager.success(
            "Ձեր նամակը հաջողությամբ ուղարկվել է",
            "Հաջողվեց"
          );
          const timer = setTimeout(() => {
            setLastSent(0);
          }, 10000);
          return () => clearTimeout(timer);
        },
        (error) => {
          setErrorMessage("Error sending email. Please try again.");
          NotificationManager.error(
            "Error",
            "Error sending email. Please try again."
          );
        }
      );
  }

  useEffect(() => {
    return () => {
      if (errorMessageTimeout.current) {
        clearTimeout(errorMessageTimeout.current);
      }
    };
  }, []);

  return (
    <div className={styles.formCont}>
      <div className={styles.black}></div>
      <div className={styles.formForm}>
        <img
          src={logo}
          alt=""
          width="128"
        />
        <p className={styles.mainTitle}>
          Խնդրում ենք հաստատել Ձեր ներկայությունը
        </p>
        <form ref={form} onSubmit={(e) => Submit(e)} className="form">
          <input
            type="text"
            name="Name"
            placeholder="Անուն Ազգանուն"
            className={`${styles.guestName} ${
              errorMessage ? styles.error : ""
            }`}
          />
          <input
            type="text"
            name="Count"
            placeholder="Հյուրերի քանակ"
            className={`${styles.guestName} ${
              errorMessage ? styles.error : ""
            }`}
          />
          {errorMessage && (
            <p className={styles.errorMessage}>{errorMessage}</p>
          )}
          <div className={styles.onlyRestaurant}>
            <input
              type="radio"
              name="Coming"
              value="Իհարկե, սիրով կգամ"
              id="onlyRestaurant"
              onChange={handleRadioChange}
            />
            <label htmlFor="onlyRestaurant">Իհարկե, սիրով կգամ</label>
          </div>
          <div className={styles.cantCome}>
            <input
              type="radio"
              name="Coming"
              value="Ցավոք, չեմ կարողանա գալ"
              id="cantCome"
              onChange={handleRadioChange}
            />
            <label htmlFor="cantCome">Ցավոք, չեմ կարողանա գալ</label>
          </div>
          <input
            type="submit"
            value="Ուղարկել"
            id="contactUs"
            className={`${styles.sendBtn} ${lastSent ? styles.disabled : ""}`}
          />
        </form>
      </div>
    </div>
  );
};
