import church from "../../../assets/images/chrch.jpg";
import rest from "../../../assets/images/rest.jpg";

import styles from "./Models.module.scss";

export default function Models() {
  return (
    <div className={styles.models}>
      <div className={styles.chrchBox}>
        <img src={church} alt="" />
        <a
          href="https://yandex.com/maps/org/52611210913/?ll=44.766835%2C40.531382&z=12.93"
          target="_blank"
          rel="noreferrer"
        >
          Ինչպես հասնել
        </a>
      </div>
      <div className={styles.restBox}>
        <img src={rest} alt="" className={styles.rest} />
        <a
          href="https://yandex.ru/maps/org/love_garden/55193755324/?ll=44.583195,40.299636&z=15"
          target="_blank"
          rel="noreferrer"
        >
          Ինչպես հասնել
        </a>
      </div>
    </div>
  );
}
