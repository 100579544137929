import React, { useEffect, useState } from "react";
import Navbar from "./Navbar/Navbar";
import Header from "./Header/Header";
import Main from "./Main/Main";
import Footer from "../Footer/Footer";
import Loader from "./Loader/Loader";
import { NotificationContainer } from "react-notifications";
import { geoJul } from "../../constant/seo";
import { Helmet } from "react-helmet-async";
import SeoCard from "../seoCard";

import styles from "./Wedding.module.scss";

const Wedding = () => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 30);

      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isLoading]);

  return (
    <div className={styles.App}>
      {isLoading && <Loader />}
      <div className={styles.position}>
        <Navbar />
      </div>
      <Header onImageLoad={handleImageLoad} isLoading={isLoading} />
      <Main />
      <Footer />
      <NotificationContainer />
      <SeoCard seo={geoJul} />
      <Helmet>
        <title>Գեորգի & Յուլիա</title>
        <meta name="description" content="Գեորգի և Յուլիա" />
      </Helmet>
    </div>
  );
};

export default Wedding;
