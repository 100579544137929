import BackgroundMusic from "../BackgroundMusic/BackgroundMusic";
import styles from "./Navbar.module.scss";

export default function Navbar({ isPlaying, setIsPlaying, audioRef }) {
  return (
    <nav>
      <div className={styles.backgroundMusic}>
        <BackgroundMusic
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          audioRef={audioRef}
        />
      </div>
    </nav>
  );
}
