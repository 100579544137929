import styles from "./Loader.module.scss";
import img from "../../../assets/images/weddingTwo/h1.webp";

export default function Loader() {
  return (
    <div className={styles.loaderContainer}>
      <img src={img} alt="loader"/>
      <div className={styles.loaderInfo}>
        <p>Wait for a Magic</p>
        <div class={styles.lds_heart}>
          <div></div>
        </div>
      </div>
    </div>
  );
}
