export const homeSEO = {
  title: "Հարսանյաց հրավիրատոմս",
  keywords:
    "Հարսանյաց հրավիրատոմս",
  description:
    "Hraviratomser.am Հարսանյաց հրավիրատոմս",
  ogType: "website",
  ogURL: "https://hraviratomser.am/",
};

export const geoJul = {
  title: "Գեորգի և Յուլիա",
  keywords: "Գեորգի և Յուլիա",
  description: "Գեորգի և Յուլիա ❤ Հարսանյաց հրավիրատոմս",
  ogType: "website",
  ogURL: "https://hraviratomser.am/georgi-yulia",
};

export const geoAni = {
  title: "Բենո և Անի",
  keywords: "Բենո և Անի",
  description: "Բենո և Անի ❤ Հարսանյաց հրավիրատոմս",
  ogType: "website",
  ogURL: "https://hraviratomser.am/beno-ani",
};

export const geoXY = {
  title: "Ալեքսանդր և Կարինա",
  keywords: "Ալեքսանդր և Կարինա",
  description: "Ալեքսանդր և Կարինա ❤ Հարսանյաց հրավիրատոմս",
  ogType: "website",
  ogURL: "https://hraviratomser.am/alexandr-karina",
};

export const geoArthur = {
  title: "Արթուր և Լուսինե",
  keywords: "Արթուր և Լուսինե",
  description: "Արթուր և Լուսինե ❤ Հարսանյաց հրավիրատոմս",
  ogType: "website",
  ogURL: "https://hraviratomser.am/artur-lusine",
};
