import church from "../../../assets/images/weddingTwo/h4.jpeg";
import rest from "../../../assets/images/weddingTwo/h5.jpeg";

import styles from "./Models.module.scss";

export default function Models() {
  return (
    <div className={styles.models}>
      <div className={styles.chrchBox}>
        <img src={church} alt="" />
        <a
          href="https://yandex.ru/maps/-/CDwGm6ld"
          target="_blank"
          rel="noreferrer"
        >
          Ինչպես հասնել
        </a>
      </div>
      <div className={styles.restBox}>
        <img src={rest} alt="" className={styles.rest} />
        <a
          href="https://yandex.ru/maps/-/CDwGmG2j"
          target="_blank"
          rel="noreferrer"
        >
          Ինչպես հասնել
        </a>
      </div>
    </div>
  );
}
