import React, { useState, useEffect } from "react";

import styles from "./Timer.module.scss";

export default function Timer({ targetDate }) {
  const [timeLeft, setTimeLeft] = useState(getTimeRemaining());

  function getTimeRemaining() {
    const now = new Date().getTime();
    const targetTime = new Date(targetDate).getTime();
    const timeRemaining = targetTime - now;

    if (timeRemaining <= 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const seconds = Math.floor((timeRemaining / 1000) % 60);
    const minutes = Math.floor((timeRemaining / 1000 / 60) % 60);
    const hours = Math.floor((timeRemaining / (1000 * 60 * 60)) % 24);
    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(getTimeRemaining());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={styles.timerBox}>
      <h1 className={styles.timerHead}>Հարսանիքին մնացել է</h1>
      <div className={styles.timerCont}>
        <div className={styles.time}>
          {timeLeft.days > 0 && <div>{timeLeft.days} օր</div>}
        </div>
        <div className={styles.border}></div>
        <div className={styles.time}>{timeLeft.hours} ժամ</div>
        <div className={styles.border}></div>
        <div className={styles.time}>{timeLeft.minutes} րոպե</div>
        <div className={styles.border}></div>
        <div className={styles.time}>{timeLeft.seconds} վայրկյան</div>
        <div className={styles.border}></div>
      </div>
      {/* <div className={styles.text}>
        <p>Բան չմնաց 🤍</p>
      </div> */}
    </div>
  );
}
