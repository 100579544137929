import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import Header from "./Header/Header";
import Main from "./Main/Main";
import Footer from "../Footer/Footer";
import SeoCard from "../seoCard";
import AboutProgress from "./aboutProgress/AboutProgress";
import { NotificationContainer } from "react-notifications";
import { geoArthur } from "../../constant/seo";

import styles from "./Wedding.module.scss";

const WeddingFour = () => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };

  // useLayoutEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  // useEffect(() => {
  //   if (isLoading) {
  //     setTimeout(() => {
  //       window.scrollTo(0, 0);
  //     }, 30);

  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "auto";
  //   }
  //   return () => {
  //     document.body.style.overflow = "auto";
  //   };
  // }, [isLoading]);

  return (
    <div className={styles.App}>
      <Header onImageLoad={handleImageLoad} isLoading={isLoading} />
      <Main />
      <AboutProgress />
      <Footer />
      <NotificationContainer />
      <SeoCard seo={geoArthur} />
      <Helmet>
        <title>Արթուր & Լուսինե</title>
        <meta name="description" content="Արթուր և Լուսինե" />
      </Helmet>
    </div>
  );
};

export default WeddingFour;
