import MovingText from "react-moving-text";
import logo from "../../../assets/images/weddingThree/logo.png";

import styles from "./Loader.module.scss";

export default function Loader({ setIsLoading, isLoading, startMusic }) {
  const handleClick = () => {
    setIsLoading(!isLoading);
    startMusic();
  };
  return (
    <div className={styles.loaderContainer}>
      <div className={styles.loaderInfo}>
        <MovingText
          type="popIn"
          duration="4000ms"
          delay="0s"
          direction="alternate"
          timing="ease"
          iteration="1"
          fillMode="none"
        >
          <img src={logo} alt="" />
        </MovingText>

        <div className={styles.bridesCont}>
          <p className={styles.brides}>ԱԼԵՔՍԱՆԴՐ և ԿԱՐԻՆԱ</p>
          <p className={styles.bridesMobile}>
            ԱԼԵՔՍԱՆԴՐ <br /> և <br /> ԿԱՐԻՆԱ
          </p>
          {/* <p className={styles.brides}>ALEXANDR and KARINA</p> */}
          <p className={styles.invite}>Հարսանյաց հրավեր</p>
          <button onClick={handleClick}>Դիտել հրավերը</button>
        </div>
      </div>
    </div>
  );
}
