import React, { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import img from "../../../assets/images/34.jpg";
import styles from "./ContactUs.module.scss";
import Timer from "../Timer/Timer";

export const ContactUs = () => {
  const form = useRef();
  const [errorMessage, setErrorMessage] = useState("");
  const [lastSent, setLastSent] = useState(0); // Timestamp of the last send
  const targetDate = "2024-10-05T12:00:00";

  let errorMessageTimeout = useRef(null); // Ref to store the timeout
  const [selectedRadio, setSelectedRadio] = useState({
    name: "",
    value: "",
  });

  const handleRadioChange = (e) => {
    setSelectedRadio({
      name: e.target.name,
      value: e.target.value,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    const userName = form.current.user_name.value;

    const now = Date.now();
    if (now - lastSent < 10000) {
      // 10,000 milliseconds = 10 seconds
      setErrorMessage("Please wait 10 seconds between submissions.");
      errorMessageTimeout.current = setTimeout(() => {
        setErrorMessage(""); // Clear the error message after 10 seconds
      }, 10000);
      return;
    }

    let hasError = false;

    if (!selectedRadio.value || !userName.trim()) {
      setErrorMessage("Խնդրում ենք լրացնել բոլոր դաշտերը");
      hasError = true; // Set error flag
    }

    if (hasError) return;

    emailjs
      .sendForm(
        "service_4zfi84i",
        "template_p6hirqr",
        form.current,
        "uLq7FAJyPzhHOGzW7"
      )
      .then(
        (result) => {
          console.log(result.text);
          setErrorMessage(""); // clear error message on successful send
          setLastSent(now); // Update the last sent timestamp
          NotificationManager.success(
            "Ձեր նամակը հաջողությամբ ուղարկվել է",
            "Հաջողվեց"
          );
          const timer = setTimeout(() => {
            setLastSent(0); // Reset the lastSent timestamp after 10 seconds
          }, 10000);

          // Clear the timeout when the component is unmounted
          return () => clearTimeout(timer);
        },
        (error) => {
          console.log(error.text);
          setErrorMessage("Error sending email. Please try again.");
          NotificationManager.error(
            "Error",
            "Error sending email. Please try again."
          );

          // update with a more specific error message if needed
        }
      );
  };

  useEffect(() => {
    return () => {
      if (errorMessageTimeout.current) {
        clearTimeout(errorMessageTimeout.current);
      }
    };
  }, []);

  return (
    <div className={styles.formCont}>
      <div className={styles.imgContact}>
        <Timer targetDate={targetDate} />
      </div>
      <div className={styles.formForm}>
        <p className={styles.mainTitle}>
          Խնդրում ենք նշել Ձեր ներկայությունը միջոցառմանը
        </p>
        <p className={styles.preTitle}>
          Ձեր պատասխանները մեզ կօգնեն կազմակերպել հարսանյաց արարողությունը
        </p>
        <form ref={form} onSubmit={sendEmail} className={styles.form}>
          <label className={styles.waitingYou}>
            Կսպասենք պատասխանի մինչև 01.10.2024թ
          </label>
          <div
            className={`${styles.formData} ${errorMessage ? styles.error : ""}`}
          >
            <div className={styles.allIsOk}>
              <input
                type="radio"
                name="user_radio"
                value="Կգամ պսակադրությանը և ռեստորան"
                id="allIsOk"
                onChange={handleRadioChange}
              />
              <label htmlFor="allIsOk">Կգամ պսակադրությանը և ռեստորան</label>
            </div>
            <div className={styles.onlyRestaurant}>
              <input
                type="radio"
                name="user_radio"
                value="Կգամ ռեստորան"
                id="onlyRestaurant"
                onChange={handleRadioChange}
              />
              <label htmlFor="onlyRestaurant">Կգամ ռեստորան</label>
            </div>

            <div className={styles.cantCome}>
              <input
                type="radio"
                name="user_radio"
                value="Չեմ կարող գալ"
                id="cantCome"
                onChange={handleRadioChange}
              />
              <label htmlFor="cantCome">Չեմ կարող գալ 😔</label>
            </div>
            <div className={styles.customSelectContainer}>
              <select className={styles.customSelect} name="user_count">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option selected hidden>
                  Ընտրեք քանակ
                </option>
              </select>
            </div>
          </div>

          <p className={styles.selectName}>Գրեք Ձեր Անուն Ազգանունը</p>
          <p className={styles.waitingYou}>
            Խնդրում ենք գրեք բոլոր ներկա գտնվողների անունները
          </p>
          <input
            type="text"
            name="user_name"
            placeholder="Անուն Ազգանուն"
            className={`${styles.guestName} ${
              errorMessage ? styles.error : ""
            }`}
          />
          {errorMessage && (
            <p className={styles.errorMessage}>{errorMessage}</p>
          )}

          <input
            type="submit"
            value="Ուղարկել"
            id="contactUs"
            className={`${styles.sendBtn} ${lastSent ? styles.disabled : ""}`}
          />

          {/* <input
          type="hidden"
          name="user_radio_name"
          value={selectedRadio.name}
        />
        <input
          type="hidden"
          name="user_radio_value"
          value={selectedRadio.value}
        /> */}
        </form>
      </div>
    </div>
  );
};
